<template>
  <div>
      <v-card flat>
    <v-card-text
      class="pb-0"
    >
      <v-img
        src="/poster/bgl-march.png"
        height="730"
      >
      </v-img>
    </v-card-text>
  </v-card>
  <v-card flat>
    <v-card-text
      class="pb-0"
    >
      <v-img
        src="/poster/bgl-feb.png"
        height="730"
      >
      </v-img>
    </v-card-text>
  </v-card>
  </div>
</template>

<script>
export default {}
</script>
